<template>
  <el-menu
    :default-active="activeIndex"
    class=""
    mode="horizontal"
    @select="handleSelect"
  >
    <el-menu-item index="1">Medecins</el-menu-item>
    <el-menu-item index="2">Contrats</el-menu-item>
    <el-menu-item index="6">Parcours cliniques</el-menu-item>
    <el-menu-item index="4">Activités hors-clinique</el-menu-item>
    <el-submenu index="3">
      <template slot="title">Formations</template>
      <el-menu-item index="3-1">Prégraduées</el-menu-item>
      <el-menu-item index="3-2">Postgraduées</el-menu-item>
      <el-menu-item index="3-3">Continues</el-menu-item>
      <el-menu-item index="3-4">Thèses</el-menu-item>
    </el-submenu>
    <el-menu-item index="5">Tutorats</el-menu-item>
  </el-menu>
</template>
<script>
export default {
  computed: {
    activeIndex() {
      const path = this.$route.path;
      if (path == "/" || path.startsWith("/medecins")) {
        return "1";
      } else if (path.startsWith("/contrats")) {
        return "2";
      } else if (path.startsWith("/fpregrads")) {
        return "3-1";
      } else if (path.startsWith("/fpostgrads")) {
        return "3-2";
      } else if (path.startsWith("/fcontinues")) {
        return "3-3";
      } else if (path.startsWith("/theses")) {
        return "3-4";
      } else if (path.startsWith("/hcactivites")) {
        return "4";
      } else if (path.startsWith("/tutorats")) {
        return "5";
      } else if (path.startsWith("/parcourscliniques")) {
        return "6";
      } else {
        return "7";
      }
    },
  },
  methods: {
    handleSelect(key) {
      if (key == "1") {
        this.$router.push({ name: "medecins" });
      } else if (key == "2") {
        this.$router.push({ name: "contrats" });
      } else if (key == "3-1") {
        this.$router.push({ name: "fpregrads" });
      } else if (key == "3-2") {
        this.$router.push({ name: "fpostgrads" });
      } else if (key == "3-3") {
        this.$router.push({ name: "fcontinues" });
      } else if (key == "3-4") {
        this.$router.push({ name: "theses" });
      } else if (key == "4") {
        this.$router.push({ name: "hcactivites" });
      } else if (key == "5") {
        this.$router.push({ name: "tutorats" });
      } else if (key == "6") {
        this.$router.push({ name: "parcourscliniques" });
      }
    },
  },
};
</script>
