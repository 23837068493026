<template>
  <el-container>
    <el-header>
      <app-navmenutop></app-navmenutop>
    </el-header>
    <el-container>
      <router-view />
    </el-container>
  </el-container>
</template>
<script>
import AppNavmenutop from "@/components/rh/layout/AppNavmenutop";

export default {
  components: { "app-navmenutop": AppNavmenutop },
  data() {
    return {
      //
    };
  },
};
</script>
